<template>
  <div class="printIndex">
    <div class="print" :style="{'margin-left':(screenWidth>850? screenWidth/2 - 400 + 'px':'')}">
      <resumeCanvas :taskData="taskData" />
    </div>
    <div class="showBox">
      <div class="show-userInfo" v-if="taskData.data">
        <div class="su-left">
          <img :src="taskData.user.headimg" alt />
        </div>
        <div class="su-right">
          <div class="su-item">
            <div class="lable">姓名：</div>
            <div class="text">{{taskData.user.name}}</div>
          </div>
          <div class="su-item">
            <div class="lable">所属施工单位：</div>
            <div class="text">{{taskData.user.corp_name}}</div>
          </div>
          <div class="su-item">
            <div class="lable">所属班组：</div>
            <div class="text">{{taskData.user.team_name}}</div>
          </div>
          <div class="su-item">
            <div class="lable">所属项目：</div>
            <div class="text">{{taskData.user.project_name}}</div>
          </div>
          <div class="su-item">
            <div class="lable">考试成绩：</div>
            <div class="text">100分</div>
          </div>
        </div>
      </div>
      <div class="show-taskContent">
        <div class="task-title">{{taskData.task_name}}</div>
        <div class="questionList">
          <div class="questions-box" id="questionsBox">
            <div class="list" :id="'qlist' + index" v-for="(item,index) in taskData.data">
              <div class="questions">{{(index + 1) + '、' + item.content}}</div>
              <div class="answer">
                <div class="answer-item">
                  A
                  <span>正确</span>
                  <!-- <img v-if="item.answer" :src="rightImgBase64" alt=""> -->
                  <Icon v-if="item.answer" type="md-checkmark-circle-outline" />
                </div>
                <div class="answer-item">
                  B
                  <span>错误</span>
                  <!-- <img v-if="!item.answer" :src="rightImgBase64" alt=""> -->
                  <Icon v-if="!item.answer" type="md-checkmark-circle-outline" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="editbox">
      <Button type="primary" shape="circle" @click="getPdf(taskData)">导出为PDF</Button>
    </div>
    <div class="toBrowser" id="tobrowser" style="display:none">
      <div class="imgBox">
        <img class="img1" src="../../assets/images/line.png" alt="">
        <img class="img2" src="../../assets/images/tips1.png" alt="">
        <img class="img3" src="../../assets/images/tips2.png" alt="">
      </div>
    </div>
    <div id="showImg" />
  </div>
</template>
<script>
import resumeCanvas from "./testPaperCanvas.vue";
import copyrightCom from "./copyrightCom";
export default {
  components: {
    resumeCanvas,
    copyrightCom
  },
  data() {
    return {
      imageUrl: "",
      imgResult: "",
      title: "",
      taskData: {},
      screenWidth: document.body.clientWidth,
    };
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    this.getUserPaperByTask()
  },
  watch: {
    screenWidth(val) {
      if (!this.timer) {
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function() {
          console.log(that.screenWidth);
        }, 400);
      }
    }
  },
  methods: {
    async getUserPaperByTask(id) {
      // console.log(this.$route.query)
      let phone = this.$route.query.phone
      let task_id =this.$route.query.task_id
      let team_id =this.$route.query.team_id
      let res = await this.$fetch(`/rlapi/videoQuestion/getUserPaperByTask?phone=${phone}&task_id=${task_id}&team_id=${team_id}`);
      if (res) {
        this.taskData = res.data
      }
    },
  }
};
</script>
<style>
body{
  height: auto;
}
</style>
<style lang="stylus" scoped>
*
  box-sizing border-box
.printIndex{
  position relative
  background #fff
}
.toBrowser{
  position fixed
  left 0
  top 0
  width 100%
  height 100%
  display flex
  justify-content center
  background rgba(0,0,0,0.5)
  .imgBox{
    display flex
    flex-direction column
    width 100%
    max-width 1080px
    padding 0 30px
    img{
      width 100%
      height auto
    }
    .img1 {
      padding-top: 30px;
    }
    .img2 {
      margin-top: 60px;
      width: 50vw;
    }
    .img3 {
      margin-top: 20px;
      width: 60vw;
    }
  }
}
.editbox {
  width 100px
  position: fixed;
  left 50%
  bottom: 10%;
  margin-left -50px
  text-align center
}
.showBox
  max-width 210mm
  width 100%
  background #fff
  padding 30px 40px
  margin 0 auto
  .show-userInfo
    display flex
    justify-content center
    .su-left {
      width: 25mm;
      height: 35mm;
      margin-right: 30px;

      img {
        width: 25mm;
        height: 35mm;
        display: flex;
        align-items: center;
      }
    }
    .su-right {
      min-height: 35mm;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .su-item {
        display: flex;

        .lable {
          width: 100px;
          font-weight: bold;
        }

        .text {
          flex: 1;
        }
      }
    }
  .show-taskContent
    .task-title {
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin: 40px auto;
    }
    .questionList
      width: 100%;

      .list:not(:last-child) {
        margin-bottom: 20px;
      }

      .list {
        width: 100%;

        .questions {
          width: 100%;
          margin-bottom: 10px;
        }

        .answer {
          display: flex;
          height 20px
          line-height 20px
          .answer-item:not(:last-child) {
            margin-right: 25px;
          }

          .answer-item {
            display flex
            align-items center
            .ivu-icon{
              margin-left 5px;
            }
            img{
              margin-left 5px;
              width 18px
              heigth auto
            }
            span {
              margin-left: 10px;
            }
          }
        }
      }
</style>